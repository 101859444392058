import {Button, Container, Carousel, Image, Row, Col} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import "./carousel.css";
import Banner from "../../assets/img/banner_image_2.png"
import AppStore from "../../assets/img/logo_App_Store.svg"
import GooglePlay from "../../assets/img/logo_Play_Market.svg"

const CarouselComponent = () => {
    const {t, i18n} = useTranslation();
    return (
        <div className='custom-carousel-block'>
            <Container>
                <Carousel fade controls={false} indicators={false}>
                    <Carousel.Item className='custom-carousel-item'>
                        <Image src={Banner} fluid style={{opacity: '0.4'}} />
                        <div className="new-title">
                            <h1>
                                {t('get_job')}
                            </h1>
                        </div>
                    </Carousel.Item>
                </Carousel>
            </Container>
        </div>
    )
}

export default CarouselComponent;
