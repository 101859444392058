import React, {Component, useEffect, useState} from "react";
import {Container, Carousel, Image, Row, Col, Card, Button, Badge} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import "./presentation.css";
import Slide1 from "../../assets/img/image_1.png";
import Slide2 from "../../assets/img/image_2.png";
import Slide3 from "../../assets/img/image_3.png";
import Slide4 from "../../assets/img/image_4.png";
import Phone from "../../assets/img/phone.svg";
import Slider from "react-slick";
import {ReactComponent as CloseIcon} from '../../assets/img/x-circle-fill.svg';
import {ReactComponent as CheckIcon} from '../../assets/img/check-circle-fill.svg';
import Flicking from "@egjs/react-flicking";
import "@egjs/react-flicking/dist/flicking.css";

import {setJobType} from '../../slices/jobType';
import {useSelector, useDispatch} from 'react-redux';

const fakeSubscriptionPlan = [
    {
        name: 'Junker',
        monthPrice: '0',
        yearPrice: null,
        offerCount: '1',
        freeRefresh: null,
        duration: '30',
        appointments: null,
        boost: null,
        offerSymbols: '3000',
        notifications: true,
        extension: false,
        pictures: '1',
        save: null
    },
    {
        name: 'Knight',
        monthPrice: '199',
        yearPrice: null,
        offerCount: '1',
        freeRefresh: null,
        duration: '15',
        appointments: '20',
        boost: null,
        offerSymbols: '3000',
        notifications: true,
        extension: false,
        pictures: '1',
        save: null
    },
    {
        name: 'Graph',
        monthPrice: '499',
        yearPrice: null,
        offerCount: '3',
        freeRefresh: '30',
        duration: 'Unlimited',
        appointments: '15',
        boost: null,
        offerSymbols: '3000',
        notifications: true,
        extension: false,
        pictures: '3',
        save: null
    },
    {
        name: 'King',
        monthPrice: '799',
        yearPrice: '8600',
        offerCount: '7',
        freeRefresh: '20',
        duration: 'Unlimited',
        appointments: '10',
        boost: null,
        offerSymbols: '3000',
        notifications: true,
        extension: true,
        pictures: '5',
        save: '10%'
    },
    {
        name: 'Empreror',
        monthPrice: '999',
        yearPrice: '9999',
        offerCount: 'Unlimited',
        freeRefresh: '10',
        duration: 'Unlimited',
        appointments: '5',
        boost: null,
        offerSymbols: '3000',
        notifications: true,
        extension: true,
        pictures: '10',
        save: '17%'
    }
]

const Presentation = () => {
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const selectedJobType = useSelector(state => state.jobType);
    const currencyFormat = (price) => price.toLocaleString("en-US", {style: "currency", currency: "EUR", minimumFractionDigits: 0, });

    // console.log("selectedJobType", selectedJobType)

    const settings = {
        className: "center",
        centerPadding: "60px",
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        swipeToSlide: true,
        arrows: false,
        centerMode: true,
        dots: false,
        infinite: false,
        speed: 500,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ],
        afterChange: function (index) {
            console.log(
                `Slider Changed to: ${index + 1}, background: #222; color: #bada55`
            );
        }
    };

    return (
        <div className='presentaion-block'>
            <Container>
                {selectedJobType.jobType === 'personal' && (
                    <>
                        <Row className="d-none d-md-flex">
                            <Col md={3} className='phone-item'>
                                <div className='phone-mockup-style'>
                                    <Image src={Phone} className='phone-mockup-img' />
                                    <Image src={Slide1} className='phone-banner' />
                                </div>
                            </Col>
                            <Col md={3} className='phone-item'>
                                <div className='phone-mockup-style'>
                                    <Image src={Phone} className='phone-mockup-img' />
                                    <Image src={Slide4} className='phone-banner' />
                                </div>
                            </Col>
                            <Col md={3} className='phone-item'>
                                <div className='phone-mockup-style'>
                                    <Image src={Phone} className='phone-mockup-img' />
                                    <Image src={Slide2} className='phone-banner' />
                                </div>
                            </Col>
                            <Col md={3} className='phone-item'>
                                <div className='phone-mockup-style'>
                                    <Image src={Phone} className='phone-mockup-img' />
                                    <Image src={Slide3} className='phone-banner' />
                                </div>
                            </Col>
                        </Row>
                        <Row className="d-flex d-md-none">
                            <Col>
                                <div className="mobile-slider-container">
                                    <Slider {...settings}>
                                        <div className='phone-banner' >
                                            <div className='mobile-phone-mockup-style'>
                                                <Image src={Slide1} className='phone-banner' />
                                            </div>
                                        </div>
                                        <div className='phone-banner'>
                                            <div className='mobile-phone-mockup-style'>
                                                <Image src={Slide4} className='phone-banner' />
                                            </div>
                                        </div>
                                        <div className='phone-banner'>
                                            <div className='mobile-phone-mockup-style'>
                                                <Image src={Slide2} className='phone-banner' />
                                            </div>
                                        </div>
                                        <div className='phone-banner'>
                                            <div className='mobile-phone-mockup-style'>
                                                <Image src={Slide3} className='phone-banner' />
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </Col>
                        </Row>
                    </>
                )}
                {selectedJobType.jobType === 'business' && (
                    <div className="company-subscription-section">
                        <div className="d-flex subscaption-items-block" style={{flexWrap: 'nowrap'}} >
                            <Flicking moveType="freeScroll" align='center' renderOnlyVisible={true} bound={true} className={i18n.language}>
                                {fakeSubscriptionPlan.map((plan, index) =>
                                    <Card key={index} >
                                        <div className="subscaption-card-item text-left" style={{width: "100%"}}>
                                            <div className="item-header text-center">
                                                <h4>{t('plan.' + plan.name.toLowerCase())}</h4>
                                                <p>{t('plan.plan')}</p>
                                                <div className="month-price"><span>{currencyFormat(+plan.monthPrice)}</span> / {t('plan.month')}</div>
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <div className="year-price">{plan.yearPrice && (<><span>{currencyFormat(+plan.yearPrice)}</span> / {t('plan.year')}</>)}</div>

                                                    {plan.name === 'Junker' && (
                                                        <div className='junker-style'>
                                                            <span>
                                                                {t('plan.zum')}
                                                            </span>
                                                            <span>
                                                                {t('plan.keine')}
                                                            </span>
                                                        </div>
                                                    )}

                                                    <Badge pill bg="success">
                                                        {plan.save && (
                                                            <>{plan.save}<br /> {t('plan.save')}</>
                                                        )}
                                                    </Badge>
                                                </div>
                                                <div className="free-month mt-2">
                                                    <Badge pill bg="warning" text="dark">
                                                        {t('plan.free_months')}
                                                    </Badge>
                                                </div>
                                            </div>
                                            <ul className="offer-list">
                                                <li className='offer-list-item'>
                                                    <p>{t('plans.offerCount')}</p>
                                                    <span>{plan.offerCount !== 'Unlimited' ? plan.offerCount : t('plan.unlimited')}</span>
                                                </li>
                                                <li className='offer-list-item'>
                                                    <p>{t('plans.freeRefresh')}</p>
                                                    <span>{plan.freeRefresh ? t('plans.freeRefreshCount', {count: plan.freeRefresh}) : (<CloseIcon className="icon-close-circle" />)}</span>
                                                </li>
                                                <li className='offer-list-item'>
                                                    <p>{t('plans.duration')}</p>
                                                    <span>{plan.duration !== 'Unlimited' ? plan.duration + ' T' : t('plan.unlimited')}</span>
                                                </li>
                                                <li className='offer-list-item'>
                                                    <p>{t('plans.appointments')}</p>
                                                    <span>{plan.appointments ? currencyFormat(+plan.appointments) : (<span>-</span>)}</span>
                                                </li>
                                                <li className='offer-list-item'>
                                                    <p>{t('plans.offerSymbols')}</p>
                                                    <span>{plan.offerSymbols}</span>
                                                </li>
                                                <li className='offer-list-item'>
                                                    <p>{t('plans.notifications')}</p>
                                                    <span>{plan.notifications ? (<CheckIcon className="icon-check-circle" />) : (<CloseIcon className="icon-close-circle" />)}</span>
                                                </li>
                                                {/*<li className='offer-list-item'>*/}
                                                {/*    <span>{plan.extension ? (<CheckIcon className="icon-check-circle" />) : (<CloseIcon className="icon-close-circle" />)}</span>*/}
                                                {/*    <p>Free ad extension for new customers in case of dissatisfaction</p>*/}
                                                {/*</li>*/}
                                                <li className='offer-list-item'>
                                                    <p>{t('plans.pictures')}</p>
                                                    <span>{plan.pictures ? plan.pictures : (<CloseIcon className="icon-close-circle" />)}</span>
                                                </li>
                                            </ul>
                                        </div>
                                        <Button className='subscription-btn' variant="outline-success">{t('choose')}</Button>
                                    </Card>
                                )}
                            </Flicking>
                        </div>
                    </div>
                )}
            </Container>

        </div>
    )
}

export default Presentation;
