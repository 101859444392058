import Header from './components/header/Header';
import CarouselComponent from './components/carousel/CarouselComponent';
import Presentation from './components/presentation/Presentation';
import Advantages from './components/advantages/Advantages';
import EasiestWay from './components/easiest/EasiestWay';
import Reviews from './components/reviews/Reviews';
import StartFinding from './components/finding/StartFinding';
import GetInTouch from "./components/getintouch/GetInTouch";
import Footer from './components/footer/Footer';
import './global.css';
import './media-queries.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ActionTop from "./components/actiontop/ActionTop";

function App() {
  return (
    <div className="App">
      <header className="header">
        <Header />
      </header>
      <main>
        <CarouselComponent />
          <ActionTop />
        <Presentation />
        {/*<Advantages />*/}
        {/*<EasiestWay />*/}
        {/*<Reviews />*/}
        <StartFinding />
        <GetInTouch />
        {/*<Footer />*/}
      </main>
    </div>
  );
}

export default App;
