import {Container, Row, Col, Button, InputGroup, Form} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import "./actiontop.css";
// import IconDown from "../../assets/img/icon_down.svg";
import {ReactComponent as IconDown} from '../../assets/img/icon_down.svg';
import React, {useState} from "react";
import {useSelector} from 'react-redux';

import {ReactComponent as CheckIcon} from '../../assets/img/check-circle-fill.svg';
const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

const ActionTop = () => {
    const {t, i18n} = useTranslation();
    const [email, setEmail] = useState('');
    const [send, setSend] = useState(false);
    const [error, setError] = useState(false);
    const selectedJobType = useSelector(state => state.jobType);

    const subscribe = () => {
        if(!validateEmail(email)) {
            setError(true)
            return;
        }
        const requestOptions = {
            method: 'POST',
         //  headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email: email, type: selectedJobType.jobType})
        };
        fetch('https://mindscape.xyz', requestOptions)
            .then(response => response.json())
            .then(data => {
                setSend(true)
                setEmail('')
                setError(false)
            })
            .catch(rejected => {
                console.log(rejected);
                setSend(true)
                setEmail('')
                setError(false)
            });
    }

    return (
        <div className='actiontop-block'>
            <Container className='actiontop-container-bg'>
                <Row>
                    <Col md={12} className='startfinding-item'>
                        <h2>
                            {t('actiontop.1')}
                        </h2>
                    </Col>
                    <Col md={12} xl={12} className='footer-item mb-3 mb-md-0'>
                        <div className="footer-subscribe">
                            <InputGroup style={{margin:"0 auto"}} >
                                <Form.Control
                                    className={ error ? 'shakeme' : ''}
                                    onChange={(e)=> {setError(false); setEmail(e.target.value)} }
                                    value={email}
                                    placeholder="Email"
                                    aria-label="Recipient's username"
                                    aria-describedby="basic-addon"
                                />
                                <Button onClick={()=> subscribe()} variant="outline-secondary" id="button-addon" className='subscribe-btn'>
                                    {send && (<><CheckIcon className="icon-check-circle"  style={{marginRight:'10px'}}/> {t('subscribe.success')}</>)}
                                    {!send && t('subscribe')}
                                </Button>
                            </InputGroup>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ActionTop;
