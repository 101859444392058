import {Container, Row, Col, Image} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import "./getintouch.css";
import AppStore from "../../assets/img/logo_App_Store.svg"
import GooglePlay from "../../assets/img/logo_Play_Market.svg"

const GetInTouch = () => {
    const {t, i18n} = useTranslation();
    return (
        <div className='getintouch-block'>
            <Container>
                <Row>
                    <Col className='getintouch-label mb-4 mb-md-0 justify-content-center'>
                        <h1>
                            {t('get_in_touch')}:
                        </h1>
                        <a style={{color: 'white'}} href={"emailto:wpcsolution2024@gmail.com"}>wpcsolution2024@gmail.com</a>
                    </Col>
                    <Col style={{display: 'none'}} md={6} lg={5} xl={4} className='getintouch-button-block'>
                        <div className='gap-4 play-store-block'>
                            <a href='https://www.apple.com/app-store/' target='_blank'>
                                <Image src={AppStore} />
                            </a>
                            <a href='https://play.google.com/store/apps?hl=en&gl=US' target='_blank'>
                                <Image src={GooglePlay} />
                            </a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default GetInTouch;
