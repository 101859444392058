import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from '@reduxjs/toolkit'

import { setupListeners } from '@reduxjs/toolkit/query'
import jobTypeReducer from "./jobType"
import storage from 'redux-persist/lib/storage/session'
import {
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist'
const persistConfig = {
    key: 'root',
    storage: storage
}
export const appReducer = combineReducers({
  jobType: jobTypeReducer,
})

const rootReducers = (state, action) => {
    return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducers);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
    })
})
setupListeners(store.dispatch)
export default store