import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    jobType: 'personal',
}

const jobTypeSlice = createSlice({
    name: 'jobTypeReducer',
    initialState,
    reducers: {
        setJobType(state, action) {
            state.jobType = action.payload
        },
    },
})

export default jobTypeSlice.reducer

export const {
    setJobType,
} = jobTypeSlice.actions