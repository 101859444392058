import {React, useEffect, useState} from "react"
import {Container, Form, Nav, Navbar, Offcanvas, Pagination, Image} from 'react-bootstrap';
import Logo from '../../assets/img/logo.svg';
import {useTranslation} from 'react-i18next';
import "./header.css";
import {ReactComponent as IconIndividual} from '../../assets/img/icon_individual.svg';
import {ReactComponent as IconBusiness} from '../../assets/img/icon_business.svg';
import MobileBurger from '../../assets/img/mobile_burger.svg';

import {setJobType} from '../../slices/jobType';
import {useSelector, useDispatch} from 'react-redux';

const lngs = {
    en: {nativeName: 'English'},
    de: {nativeName: 'Deutsch'}
};

const Header = () => {
    const dispatch = useDispatch();
    const selectedJobType = useSelector(state => state.jobType);
    const {t, i18n} = useTranslation();
    // const [switchWalue, setSwitchValue] = useState(selectedJobType.jobType);
    const [languages, setLanguages] = useState(i18n.language || 'de');
    const [isSwitchOn, setIsSwitchOn] = useState(false);

    if(selectedJobType.jobType.length < 1) {
        dispatch(setJobType('personal'))
    }

    // const onSwitchAction = () => {
    //     setIsSwitchOn(!isSwitchOn);
    // };

    // const customLangLabel = () => {
    //     return (
    //         <div className="custom-lng-style">
    //             {Object.keys(lngs).map((lng, ind) => (
    //                 <span key={ind} className={'lng-style-' + lng}>{lng}</span>
    //             ))}
    //         </div>
    //     )
    // }

    // useEffect(() => {
    //     console.log("isSwitchOn", isSwitchOn)
    //     if (isSwitchOn) {
    //         setLanguages('de')
    //         i18n.changeLanguage('de')
    //     } else {
    //         setLanguages('en')
    //         i18n.changeLanguage('en')
    //     }
    // }, [isSwitchOn])

    const SwitchLangComponent = () => {
        return (
            <Pagination onClick={(ev) => setLanguages(ev.target.text)}>
                {Object.keys(lngs).map((lng, ind) => (
                    <Pagination.Item key={ind} active={lng === languages}>{lng}</Pagination.Item>
                ))}
            </Pagination>
        )
    }

    useEffect(() => {
        const personalMode = document.querySelector('.personal_id');
        const personalModeLabel = document.querySelector('.personal_id label');
        const businessMode = document.querySelector('.business_id');
        const businessModeLabel = document.querySelector('.business_id label');

        if (businessMode && selectedJobType.jobType === 'personal') {
            businessModeLabel.style.borderTopLeftRadius = '14px';
            personalModeLabel.style.borderTopRightRadius = '0';
            businessModeLabel.style.borderBottomLeftRadius = '0';
            personalMode.style.backgroundColor = '#2a2a35';
            businessMode.style.backgroundColor = '#ffffff';
        }
        if (personalMode && selectedJobType.jobType === 'business') {
            businessModeLabel.style.borderBottomLeftRadius = '14px';
            businessModeLabel.style.borderTopLeftRadius = '0';
            personalModeLabel.style.borderTopRightRadius = '14px';
            businessMode.style.backgroundColor = '#2a2a35'
            personalMode.style.backgroundColor = '#ffffff'
        }

    }, [selectedJobType])

    useEffect(() => {
        const enLangElement = document.querySelector('#custom-switch-en')
        const deLangElement = document.querySelector('#custom-switch-de')

        if (enLangElement && i18n.language === 'en') {
            enLangElement.classList.add('checked-language-en')
            enLangElement.classList.remove('checked-language-de')
        }
        if (deLangElement && i18n.language === 'de') {
            deLangElement.classList.add('checked-language-de')
            deLangElement.classList.remove('checked-language-en')
        }
        console.log("languages", i18n.language)
        //i18n.changeLanguage(languages)
    }, [t])

    return (
        <Navbar expand='md' className="header-block">
            <Container fluid>
                <div className='header-nav-menu'>
                    <Navbar.Brand href="#">
                        <img
                            src={Logo}
                            className="d-inline-block align-top"
                            alt="Job 123 logo"
                        />
                    </Navbar.Brand>
                    <Form className='switch-app-type'
                        onChange={(event) => {
                            // setSwitchValue(event.target.value)
                            dispatch(setJobType(event.target.value))
                        }}
                    >
                        <Form.Check
                            defaultChecked={selectedJobType.jobType === 'personal'}
                            className="personal_id"
                            inline
                            label={t('job_app')}
                            name="group1"
                            type='radio'
                            id='personal_id'
                            value='personal'
                        />
                        <Form.Check
                            defaultChecked={selectedJobType.jobType === 'business'}
                            className="business_id"
                            inline
                            label={t('business')}
                            name="group1"
                            type='radio'
                            id='business_id'
                            value='business'
                        />
                    </Form>
                </div>
                <div className="header-menu-position">
                    <Navbar.Toggle aria-controls='offcanvasNavbar-expand-md' className="custom-mobile-burger-menu">
                        <Image src={MobileBurger} />
                    </Navbar.Toggle>
                    <Navbar.Offcanvas
                        id='offcanvasNavbar-expand-md'
                        aria-labelledby='offcanvasNavbarLabel-expand-md'
                        placement="end"
                    >
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title id='offcanvasNavbarLabel-expand-md'>
                                <h2 className="mobile-menu-label">JOB 123</h2>
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body className='align-items-center justify-content-end'>
                            <div className='change-language-block mb-4 mb-md-0'>
                                <Form>
                                    {Object.keys(lngs).map(lng => (
                                        <Form.Check
                                            className={i18n.language === lng ? 'active' : ''}
                                            key={lng}
                                            inline
                                            defaultChecked={lng === i18n.language}
                                            label={lng.toUpperCase()}
                                            name={'lng'}
                                            type='radio'
                                            id={'lang-radio-' + lng}
                                            value={lng}
                                            onChange={(event) => {
                                                i18n.changeLanguage(lng)
                                              //  setLanguages(event.target.value)
                                            }}
                                        />
                                    ))}
                                </Form>
                                {/* <SwitchLangComponent /> */}
                            </div>
                            <Nav className="justify-content-end login-type-style">
                                {/* <Nav.Link href="#action1" className="d-flex align-items-center mb-2 mb-md-0">
                                    <span>
                                        <IconIndividual className="user-type-icon" />
                                    </span>
                                    {t('individual_loghin')}
                                </Nav.Link> */}
                                {/*<Nav.Link href="#action2" className="d-flex align-items-center mb-2 mb-md-0">*/}
                                {/*    <span>*/}
                                {/*        <IconBusiness className="user-type-icon" />*/}
                                {/*    </span>*/}
                                {/*    {t('business_loghin')}*/}
                                {/*</Nav.Link>*/}
                            </Nav>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </div>

            </Container>
        </Navbar>
    );
}

export default Header;
