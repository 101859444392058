import {Container, Row, Col, Button} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import "./startfinding.css";
// import IconDown from "../../assets/img/icon_down.svg";
import {ReactComponent as IconDown} from '../../assets/img/icon_down.svg';

const StartFinding = () => {
    const {t, i18n} = useTranslation();
    return (
        <div className='startfinding-block'>
            <Container className='startfinding-container-bg'>
                <Row>
                    <Col md={12} className='startfinding-item'>
                        <h2>
                            {t('startfinding.1')}
                        </h2>
                        <p>  {t('startfinding.2')} <br />   {t('startfinding.3')} </p>
                    </Col>
                    <Col style={{display: 'none'}} sm={{ span: 6, offset: 3 }} xs={{ span: 10, offset: 1 }} >
                        <Button variant="outline-light" className='startfinding-btn' size="lg">
                            <span>Find a dream job</span>
                        </Button>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default StartFinding;
